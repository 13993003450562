import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Loader } from "../../assets/images/custom";

const LoaderPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Simulate an asynchronous operation
    setTimeout(() => {
      setIsLoading(false);
      if(localStorage.token){
        navigate('/dashboard');
      }else{
        navigate('/login')
      }
      
    }, 1000);
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="loader text-center" style={{height: '100vh'}}>
          <img width={250} src={Loader} />
        </div> // Replace with your loader component or CSS
      ) : (
        <div className="content">Page content goes here</div> // Replace with your actual content component
      )}
    </div>
  );
};

export default LoaderPage;
